import React, { useState, useEffect } from 'react';
import SiteContainer from "../../components/site-container";
import {Helmet} from "react-helmet";
import fetch from 'node-fetch';
import Form from 'react-bootstrap/Form'
import {Card} from "react-bootstrap";

const postSignup = async (payload) => {
    const install_response = await fetch('/api/install/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    });

    return await install_response.json()
}

const IndexPage = () => {
    const [signup, setSignup] = useState({
    });
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);

    const updateSignupValue = (key, value) => {
        let payload = {}
        payload[key] = value;
        setSignup(Object.assign({}, signup, payload))
    }

    const submit = (e) => {
        e.preventDefault();
        setSubmitting(true);

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }

        setValidated(true);

        postSignup(signup).then(data => {
            if(data.errors) {
                setErrors(data.errors);
            }
            else {
                typeof window !== "undefined" && window.gtag('event', 'conversion', { 'send_to': `${process.env.GATSBY_AW_GTAG_ID}/${process.env.GATSBY_AW_GTAG_SIGNUP_CONVERSION_ID}` });
                typeof window !== "undefined" && window.fbq('track', 'CompleteRegistration');
                typeof window !== "undefined" && window.lintrk('track', { conversion_id: 9566308 });
                setTimeout(function() {
                    window.location = data.login_url
                }, 1000)
            }
            setSubmitting(false);
        })
    }

    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Signup</title>
                <meta name="description" content="Collect up to 10 million pageviews, form completions, user activities, and any other important events, every month, for free."></meta>
                
            </Helmet>

            <section>
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-8 py-8 py-md-11">
                            <h1 className="mb-0 fw-bold">
                                🎉 Get Started for Free
                            </h1>
                            <p className="mb-6 text-muted">
                                Collect up to 10 million pageviews, form completions, user activities, and any other important events, every month, for free.
                            </p>
                            <Card border="primary" className="card-border shadow-light-lg">
                                <Card.Body>
                                    <Form validated={validated} onSubmit={submit} className="mb-3">
                                        <div className="row">
                                            <div className="col-12 col-lg-6 mb-3">
                                                <Form.Group>
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control required isInvalid={Boolean(errors.first_name)} onChange={event => updateSignupValue('first_name', event.target.value)} placeholder="David" />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.first_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <Form.Group>
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control required isInvalid={Boolean(errors.last_name)} onChange={event => updateSignupValue('last_name', event.target.value)} placeholder="Rose" />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.last_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <Form.Group>
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control required type="email" isInvalid={Boolean(errors.email)} onChange={event => updateSignupValue('email', event.target.value)} placeholder="david@roseapothecary.com" />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <Form.Group>
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control required isInvalid={Boolean(errors.password)} type="password" onChange={event => updateSignupValue('password', event.target.value)} placeholder="********" />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.password}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="col-12 col-lg-6 mb-3">
                                                <Form.Group>
                                                    <Form.Label>Company Name</Form.Label>
                                                    <Form.Control required isInvalid={Boolean(errors.organization)} onChange={event => updateSignupValue('organization', event.target.value)} placeholder="Rose Apothecary" />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.organization}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <button className="btn mt-5 w-100 btn-primary" type="submit" disabled={submitting}>
                                            {submitting ? "Creating Your Firstparty Workspace..." : "Create Account"}
                                        </button>
                                    </Form>
                                    <p className="fs-sm text-muted">
                                        By clicking "Create Account", you accept the terms of the <a href="https://firstpartyhq.com/terms/agreement/" target="_blank">Customer Agreement.</a>
                                    </p>
                                </Card.Body>
                            </Card>
                            {/*<p className="mb-0 fs-sm text-muted">*/}
                            {/*    Already have an account? <a href="https://app.firstpartyhq.com/authentication/login">Log in</a>.*/}
                            {/*</p>*/}
                        </div>
                        <div className="col-lg-4">
                            <h3 className="mb-0 fw-bold">Have Questions?</h3>
                            <p>We'd love to chat with you!</p>
                            <a href="/contact/" className="btn btn-sm btn-outline-primary">
                                Talk to Us
                            </a>
                        </div>
                    </div>

                </div>
            </section>

        </SiteContainer>
    )
}

export default IndexPage
